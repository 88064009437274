'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { Banner } from '@knapsack/toby';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    console.error(error);
    const key = 'cannotReadPropertiesReload';
    if (
      error.message.includes('Cannot read properties of ') &&
      !window.sessionStorage.getItem(key)
    ) {
      window.sessionStorage.setItem(key, 'yes');
      window.location.reload();
    }
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <Banner type="error" title={error.message} />
      </body>
    </html>
  );
}
